import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { AdminComponent } from './admin/admin.component';
import { CatalogoComponent } from './paginas/catalogo/catalogo.component';
import { ProductoComponent } from './componentes/producto/producto.component';
import { CarritoComponent } from './paginas/carrito/carrito.component';
import { GrupoComponent } from './componentes/grupo/grupo.component';
import { SoporteComponent } from './paginas/soporte/soporte.component';
import { InformacionComponent } from './paginas/informacion/informacion.component';
import { PoliticasComponent } from './paginas/politicas/politicas.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent
  },
  {
    path: 'inicio',
    component: InicioComponent
  },
  {
    path: 'catalogo',
    component: CatalogoComponent
  },
  {
    path: 'producto/:id',
    component: ProductoComponent
  },
  {
    path: 'grupo/:id',
    component: GrupoComponent
  },
  {
    path: 'carrito',
    component: CarritoComponent
  },
  {
    path: 'soporte',
    component: SoporteComponent
  },
  {
    path: 'informacion',
    component: InformacionComponent
  },
  {
    path: 'politicas/:id',
    component: PoliticasComponent
  },
  {
    path: '',
    component: InicioComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { WebService } from '../../web.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {

  public informacion: any;
  public noticias = [];

  public configuracion = {
    id: "",
    imgLogo: "",
    colorPrincipal: "",
    tituloPromocion: ""
  };

  public sobreNosotros = [];

  urlBase=environment.firebaseUrl;

  constructor(
    private webService: WebService
  ) {    

    this.webService.getNoticias().then((data: []) => {
      this.noticias = data;
      // console.log(this.noticias);
    });
   }

  ngOnInit() {
    this.getDatos();
    this.getConfiguracion();
  }

  async getDatos() {
    this.webService.getInformacion().then(async (data) => {
      this.informacion = data[0];
      let datos = [];

      // hacer funcion para intercalar contenido
      if (data[0].historia) {
        if(!data[0].imagenHistoria){
          data[0].imagenHistoria = '1LYI0smsSxQKm6mzrmQaYxZxWgxUD31KQ';
        }
        let info = {
          img: data[0].imagenHistoria,
          desc: data[0].historia,
          tit: 'HISTORIA',
          icn: 'fas fa-archway'
        }
        datos.push(info);
      }

      if (data[0].mision) {
        if(!data[0].imagenMision){
          data[0].imagenMision = '1LYI0smsSxQKm6mzrmQaYxZxWgxUD31KQ';
        }
        let info = {
          img: data[0].imagenMision,
          desc: data[0].mision,
          tit: 'MISION',
          icn: 'fas fa-globe'
        }
        datos.push(info);
      }

      if (data[0].vision) {
        if(!data[0].imagenVision){
          data[0].imagenVision = '1LYI0smsSxQKm6mzrmQaYxZxWgxUD31KQ';
        }
        let info = {
          img: data[0].imagenVision,
          desc: data[0].vision,
          tit: 'VISION',
          icn: 'fas fa-eye'
        }
        datos.push(info);
      }

      if (data[0].valores) {
        if(!data[0].imagenValores){
          data[0].imagenValores = '1LYI0smsSxQKm6mzrmQaYxZxWgxUD31KQ';
        }
        let info = {
          img: data[0].imagenValores,
          desc: data[0].valores,
          tit: 'VALORES',
          icn: 'fas fa-gem'
        }
        datos.push(info);
      }

      await this.webService.intercalarContenido(datos).then((resCont) =>{
        this.sobreNosotros = resCont;
      });

    });
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      // console.log("Configuracion", data);

      this.configuracion.id = data[0].id;
      this.configuracion.imgLogo = data[0].imgLogo;
      this.configuracion.colorPrincipal = data[0].colorPrincipal;
      this.configuracion.tituloPromocion = data[0].tituloPromocion;

      let m = document.getElementById('main-navbar');
      m.style.background = data[0].colorPrincipal;

      let fotSect = document.getElementById('footer-section');
      fotSect.style.background = data[0].colorPrincipal;

    });
  }

}

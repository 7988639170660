

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyBo_Fy27SnrmFCS-QTUaezCYUv8h1ZBOrY",
//     authDomain: "equimec-48939.firebaseapp.com",
//     databaseURL: "https://equimec-48939.firebaseio.com",
//     projectId: "equimec-48939",
//     storageBucket: "equimec-48939.appspot.com",
//     messagingSenderId: "535125083350",
//     appId: "1:535125083350:web:7fc979af3b028ddad7b577",
//     measurementId: "G-60WE1GK71M"
//   }
// };
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBo_Fy27SnrmFCS-QTUaezCYUv8h1ZBOrY",
    authDomain: "equimec-48939.firebaseapp.com",
    databaseURL: "https://equimec-48939.firebaseio.com",
    projectId: "equimec-48939",
    storageBucket: "equimec-48939.appspot.com",
    messagingSenderId: "535125083350",
    appId: "1:535125083350:web:7fc979af3b028ddad7b577",
    measurementId: "G-60WE1GK71M"
  },
    firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/cafeviviates%2f',
  idShop:39,
  nginx:'viviatesexpress.com',
  empresa:'cafeviviates'
};

// export const environment = {
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/cafeviviates%2f',
//   idShop:48,
//   nginx:'viviatesexpress.com',
//   empresa:'cafeviviates'
// }

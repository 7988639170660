import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';

import { WebService } from '../../web.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { stringify } from 'querystring';
import { auth } from 'firebase';
import { async } from '@angular/core/testing';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';


// import * as $ from "jquery";
// import { ready } from 'jquery';
// declare var jQuery: any;
// import { DomSanitizer } from '@angular/platform-browser';

export interface productoCarrito {
  id_prod: number,
  nombre: string,
  precio: number,
  cantidad: number,
  img_web: string,
  stockReal: number
}

export interface arrPlacetopay { }

let ejecutar;

// Libreria para generar el SHA-1 y Base 64
var Hashes = require('jshashes')


@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {

  @ViewChild("loadingPagoPayphone", {}) loadingPagoPayphone: TemplateRef<any>;
  @ViewChild("content", {}) content: TemplateRef<any>;
  @ViewChild("payPhone", {}) payPhone: TemplateRef<any>;
  @ViewChild("modalDataFast", {}) modalDataFast: TemplateRef<any>;
  @ViewChild("resultadoTransaccion", {}) resultadoTransaccion: TemplateRef<any>;
  @ViewChild("modalPendientePlacetopay", {}) modalPendientePlacetopay: TemplateRef<any>;
  @ViewChild("paypalModal", {}) paypalModal: TemplateRef<any>;


  public carrito: any;
  public totalCarrito;
  public prodWhatsApp = "";

  public closeResult: string;

  public datosPersonales = {
    nombres: "",
    apellidos: "",
    cedula: "",
    direccion: "",
    correo: "",
    telefono: "",
    ciudad: "",
    registrar: false,
    editar: true
  }

  public configuracion: any;

  public id_pedido;

  public loading = false;

  public html = "";
  // public fechActual;
  public observacionesPedido = '';

  public numeroCuenta;
  public nombreBanco;
  public nombreCuenta;
  public mostrarDatosCuenta = false;

  public datosCuentaBancaria = {
    mostrarBotonCuenta: false,
    mostrarDatosCuenta: false,
    valorMininoCompra: false
  }

  public nombreEmpresa;

  public verificarCtaPayphone = {
    numValido: false,
    iso2: '',
    dialCode: '',
    numero: '',
    numeroCompleto: ''
  }

  // public tokenPayphone = "";
  public costoEnvio;
  public totalPedidoCostoEnvio;
  public textoCostoEnvio = "";
  public colorCostoEnvio = "";

  public costoEnvio2;
  public textoCostoEnvio2 = "";
  public selectTipoEnvio = {
    verSelect: false,
    opcionSeleccionada: false,
    value: 1,
    tipoPedido: 0
  };

  public visibilidadBtnWhatsapp;

  public compraTarjetaCredito = {
    txtRecargoTarjetaC: "",
    porcentajeCompraTarjeta: 0,
  }

  public porcentajeCompraTC;

  public btnEnviarPedido1 = {
    texto: "",
    aplicarCostoEnvio: 0
  }

  public cantidad = 0;

  public codigoDescuento = {
    porcentaje: 0,
    texto: '',
    codigo: '',
    aplicado: false,
    descuento: '0',
    totalReal: 0
  }

  public dataFast = {
    checkoutId: '',
    respUrl: '',
    resourcePath: '',
    dominioPagina: '',
    id_pedido: ''
  }

  public ciudadEcuador = [
    { 'id': 1, 'provincia': 'Azuay', 'capital': 'Cuenca', 'selected': false },
    { 'id': 2, 'provincia': 'Bolívar', 'capital': 'Guaranda', 'selected': false },
    { 'id': 3, 'provincia': 'Cañar', 'capital': 'Azogues', 'selected': false },
    { 'id': 4, 'provincia': 'Carchi', 'capital': 'Tulcán', 'selected': false },
    { 'id': 5, 'provincia': 'Chimborazo', 'capital': 'Riobamba', 'selected': false },
    { 'id': 6, 'provincia': 'Cotopaxi', 'capital': 'Latacunga', 'selected': false },
    { 'id': 7, 'provincia': 'El Oro', 'capital': 'Machala', 'selected': false },
    { 'id': 8, 'provincia': 'Esmeraldas', 'capital': 'Esmeraldas', 'selected': false },
    { 'id': 9, 'provincia': 'Galápagos', 'capital': 'Puerto Baquerizo Moreno', 'selected': false },
    { 'id': 10, 'provincia': 'Guayas', 'capital': 'Guayaquil', 'selected': false },
    { 'id': 11, 'provincia': 'Imbabura', 'capital': 'Ibarra', 'selected': false },
    { 'id': 12, 'provincia': 'Loja', 'capital': 'Loja', 'selected': true },
    { 'id': 13, 'provincia': 'Los Ríos', 'capital': 'Babahoyo', 'selected': false },
    { 'id': 14, 'provincia': 'Manabí', 'capital': 'Portoviejo', 'selected': false },
    { 'id': 15, 'provincia': 'Morona Santiago', 'capital': 'Macas', 'selected': false },
    { 'id': 16, 'provincia': 'Napo', 'capital': 'Tena', 'selected': false },
    { 'id': 17, 'provincia': 'Orellana', 'capital': 'Francisco de Orellana', 'selected': false },
    { 'id': 18, 'provincia': 'Pastaza', 'capital': 'Puyo', 'selected': false },
    { 'id': 19, 'provincia': 'Pichincha', 'capital': 'Quito', 'selected': false },
    { 'id': 20, 'provincia': 'Santa Elena', 'capital': 'Santa Elena', 'selected': false },
    { 'id': 21, 'provincia': 'Santo Domingo de los Tsáchilas', 'capital': 'Santo Domingo', 'selected': false },
    { 'id': 22, 'provincia': 'Sucumbíos', 'capital': 'Nueva Loja', 'selected': false },
    { 'id': 23, 'provincia': 'Tungurahua', 'capital': 'Ambato', 'selected': false },
    { 'id': 24, 'provincia': 'Zamora Chinchipe', 'capital': 'Zamora', 'selected': false }
  ];

  public pasarelasPago = {
    payPhone: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    dataFast: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    placetoPay: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    payPal: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
  }

  public terminosCondiciones = {
    btnActivo: false,
    visualizar: 0
  };

  public iva = 0.12;

  // public logoPlacetopay = false;
  public logoBtnPagoFormulario = {
    payPhone: false,
    dataFast: false,
    placetoPay: false,
    paypal: false
  }

  public placetoPay = {
    requestId: '',
    processUrl: {},
    id_pedido: '',
  }

  public estadoTransaccion = {
    mensaje: '',
    referencia: '',
    estado: 0
  }

  public pendientesPlacetopay: any;

  // Boton de paypal
  public payPalConfig?: IPayPalConfig;

  constructor(
    private webService: WebService,
    private modalService: NgbModal,
    private router: Router,
    private toaster: ToastrService,
    private localStorage: LocalStorage,
    private elementRef: ElementRef,
    private domSanitizer: DomSanitizer

  ) {
    // this.localStorage.removeItem('loginDemo-pedido').subscribe((deletePedidoLS) => {console.log(deletePedidoLS);});
  }

  async ngOnInit() {

    // Obtener url si esta cancelando con Data fast
    this.dataFast.respUrl = this.router.url;
    let resPath = this.dataFast.respUrl.indexOf("resourcePath");
    // console.log(resPath);

    if (resPath > 0) {

      // Obtener el id_pedido
      let id_ped = this.dataFast.respUrl.indexOf("carrito?idPedido=");
      if (id_ped > 0) {
        let i = this.router.url.substring(id_ped, (this.router.url.length));
        let inicio = i.indexOf("=");
        let fin = i.indexOf("&")
        let final = i.substring(inicio + 1, (fin));
        this.dataFast.id_pedido = final;
      } else {
        console.log("no hay el ID");

      }

      // Obtener Resource Path
      let a = this.dataFast.respUrl.substring(resPath, (this.dataFast.respUrl.length));
      let r = a.replace(/%2F/g, '/');
      let v1 = r.indexOf("/v1");
      let resPathFinal = r.substring(v1, (r.length));
      this.dataFast.resourcePath = resPathFinal;
      // console.log("Resource path", this.dataFast.resourcePath);
    }

    // Obtener Respuesta de PlacetoPay
    let idPedido = this.router.url.indexOf("carrito?id_pedido=");
    // console.log(idPedido);
    if (idPedido > 0) {
      let i = this.router.url.substring(idPedido, (this.router.url.length));
      let id = i.indexOf("=");
      let final = i.substring(id + 1, (i.length));
      this.placetoPay.id_pedido = final;
    }

    this.carrito = this.webService.getCarrito();

    for (let prod of this.carrito) {
      prod.stockDisponible = this.webService.crearArregloStock(prod.stockReal);
    }
    // console.log("carrito", this.carrito);
    // for (var i = 0; i < this.carrito.length; i++) {
    //   // console.log(this.carrito[i].stockReal);
    //   this.carrito[i].stockDisponible = this.webService.crearArregloStock(this.carrito[i].stockReal);
    // }

    this.totalCarrito = this.webService.getTotal().toFixed(2);
    // console.log("Total", this.totalCarrito);

    this.codigoDescuento.totalReal = this.totalCarrito;

    // Aqui habilitar el boton pagar pedido cuando entreguen
    // console.log("carrito", this.carrito);
    await this.getPasarelasPago();
    await this.getConfiguracion();
    await this.getDatos();

  }

  recalcularTotal() {
    this.totalCarrito = 0;
    // console.log(this.carrito);

    this.carrito.forEach(prod => {
      this.totalCarrito += (prod.cantidad * prod.precio);
      if ((this.totalCarrito) > 0 && (this.totalCarrito <= this.configuracion.valor_minimo_compra)) {
        this.datosCuentaBancaria.valorMininoCompra = true;
      } else {
        this.datosCuentaBancaria.valorMininoCompra = false;
      }
    });
  }

  quitarDelCarrito(p) {
    this.webService.quitarDelCarrito(p);
    this.totalCarrito = this.webService.getTotal();
    if ((this.totalCarrito) > 0 && (this.totalCarrito <= this.configuracion.valor_minimo_compra)) {
      this.datosCuentaBancaria.valorMininoCompra = true;
    } else {
      this.datosCuentaBancaria.valorMininoCompra = false;
    }
    //console.log('Total del carrito',this.totalCarrito);
  }

  // Enviar pedido = true => Se guarda y se envia el pedido al correo
  // pagar Payphone = false => Se controla el almacenamiento del pedido y envio del correo
  async guardarPedido(tipoPedido, loadingPagoPayphone) {

    let respuesta;
    this.id_pedido = "";
    let pasarela_pago = '';
    let estado_transaccion = '';

    // this.obtenerFechaActual(0);
    if (tipoPedido == 1) {
      if (this.btnEnviarPedido1.aplicarCostoEnvio == 0) {
        this.costoEnvio = 0;
      }
    }

    if (tipoPedido == 4) {
      pasarela_pago = "payPhone";
      estado_transaccion = 'PENDING';
    }

    if (tipoPedido == 6) {
      pasarela_pago = "dataFast";
      estado_transaccion = 'PENDING';
    }

    if (tipoPedido == 7) {
      pasarela_pago = "placetoPay";
      estado_transaccion = 'PENDING';
    }

    if (tipoPedido == 8) {
      pasarela_pago = "payPal";
      estado_transaccion = 'PENDING';
    }

    if (this.selectTipoEnvio.opcionSeleccionada == true) {
      if (this.selectTipoEnvio.value == 1) {
        this.costoEnvio = this.costoEnvio;
      }
      if (this.selectTipoEnvio.value == 0) {
        this.costoEnvio = this.costoEnvio2;
      }
    }
    respuesta = await this.calcularDescuentosRecargosPedido(tipoPedido, this.costoEnvio, this.compraTarjetaCredito, this.codigoDescuento);

    const pedido = {
      nombres: this.datosPersonales.nombres,
      apellidos: this.datosPersonales.apellidos,
      correo: this.datosPersonales.correo,
      telefono: this.datosPersonales.telefono,
      cedula_cliente: this.datosPersonales.cedula,
      direccion_cliente: this.datosPersonales.direccion,
      ciudad: this.datosPersonales.ciudad,
      fecha_pedido: this.webService.obtenerFechaActual(0),
      total: (respuesta.totalPedido).toFixed(2),
      estado: "1",
      observaciones: this.observacionesPedido,
      costoEnvio: (respuesta.costoEnvio).toFixed(2),
      porcentajeCompraTarjeta: respuesta.porcentajeCompraTarjeta,
      porcentajeCodigoDescuento: respuesta.porcentajeCodigoDescuento,
      recargoTarjeta: respuesta.recargoTarjeta,
      descuentoCodigo: respuesta.descuentoCodigo,
      numeroCodigoDescuento: this.codigoDescuento.codigo,
      totalPagar: respuesta.totalPagar,
      estado_transaccion: estado_transaccion,
      id_transaccion: '',
      pasarela_pago: pasarela_pago,
    };

    // console.log(pedido);

    this.loading = true;

    await this.webService.insertPedido(pedido).then(async (data: any) => {

      // console.log("Pedido Guardado");

      for (let d of data.res[0]) {

        this.id_pedido = d.id_pedido;

        // 4 = Pago con Payphone
        if (tipoPedido == 4) {
          console.log("tipo Pedido: ", tipoPedido);
          this.enviarTransaccionPayphone(this.id_pedido, pedido, this.configuracion, this.pasarelasPago.payPhone, this.verificarCtaPayphone, respuesta, tipoPedido, loadingPagoPayphone, this.codigoDescuento);
        }

        // 5 = Pedido por medio de whastapp
        if (tipoPedido == 5) {
          console.log("tipo Pedido: ", tipoPedido);
          this.productoWhatsApp(pedido);
        }

        // 6 = Pago con Data Fast
        if (tipoPedido == 6) {
          this.toaster.warning('Espere un momento ...', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          this.webService.obtenerDireccionIp().then((d: any) => {
            let ipAddress = d.geobytesipaddress;
            this.enviarTransaccionDataFast(this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, ipAddress, this.codigoDescuento);
          });
        }

        // 7 = Pago con PlacetoPay
        if (tipoPedido == 7) {

          this.toaster.warning('Espere un momento ...', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          let ipAddress;
          await this.webService.obtenerDireccionIp().then(async (d: any) => {
            ipAddress = d.geobytesipaddress;
          });

          await this.webService.getPedidosBtnPagosEmpresa(pasarela_pago).then(async (res: any) => {
            let pendientesBD = [];
            for (let p of res) {
              if (p.id_pedido != this.id_pedido) {
                if (p.cedula_cliente == pedido.cedula_cliente) {
                  if (p.id_transaccion) {
                    if (p.estado_transaccion == 'PENDING') {
                      pendientesBD.push(p);
                    }
                  } else {
                    await this.webService.deletePedidoDetalle(p.id_pedido).then((res) => { });
                    await this.webService.deletePedido(p.id_pedido).then((res) => { });
                  }
                }
              }
            }

            if (pendientesBD.length > 0) {
              let pendientesPlacetopay = [];
              let key = await this.webService.createDataAuthPlacetoPay(this.pasarelasPago.placetoPay.secret_key);
              let data = {
                auth: {
                  login: this.pasarelasPago.placetoPay.token,
                  tranKey: key.tranKey,
                  nonce: key.nonce,
                  seed: key.fechaNormal
                }
              }
              for (let pend of pendientesBD) {
                await this.webService.estadoTransaccionPlacetoPay(data, this.pasarelasPago.placetoPay, pend.id_transaccion).then(async (res: any) => {
                  if (res.status.status == 'APPROVED') {
                    this.consultarEstadoTransaccionPlacetoPay(pend.id_transaccion, pend.id_pedido, this.configuracion, 0)
                  }
                  if (res.status.status == 'PENDING') {
                    pendientesPlacetopay.push(pend);
                  }
                });
              }

              if (pendientesPlacetopay.length > 0) {
                console.log("Mostar en modal", pendientesPlacetopay);
                this.modalPedidoPendientePlacetopay(this.modalPendientePlacetopay, pendientesPlacetopay, this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, ipAddress, this.codigoDescuento, this.configuracion);
              } else {
                await this.enviarTransaccionPlacetoPay(this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, ipAddress, this.codigoDescuento, this.configuracion);
                console.log("anido No tiene pendientes, enviar pedido a placetoPay");
              }
            } else {
              await this.enviarTransaccionPlacetoPay(this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, ipAddress, this.codigoDescuento, this.configuracion);
              console.log("Afuera No tiene pendientes, enviar pedido a placetoPay");
            }
          });

        }

        // 8 = Pago con payPal
        if (tipoPedido == 8) {
          this.loading = false;
          // console.log(this.pasarelasPago.payPal, this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, this.codigoDescuento, this.configuracion);
          this.pagarPayPal(this.pasarelasPago.payPal, this.id_pedido, pedido, respuesta, tipoPedido, this.carrito, this.codigoDescuento, this.configuracion);
          this.modalService.open(this.paypalModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', backdrop: 'static', keyboard: false }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            // console.log("Close", result);
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            // console.log("Cancelar", reason);
            this.webService.getPedidoId(this.id_pedido).then((resPedido: any) => {
              // console.log(resPedido[0]);
              if (!resPedido[0].id_transaccion) {
                // console.log("Eliminar toddoooo");
                this.webService.deletePedidoDetalle(this.id_pedido).then((res) => { });
                this.webService.deletePedido(this.id_pedido).then((res) => { });
              }
            });
          });
        }
      }

      // Insertar el detalle del pedido
      this.carrito.forEach(det => {
        let detalle = {
          id_pedido: this.id_pedido,
          producto: det.nombre,
          pro_cod: det.id_prod,
          cantidad: det.cantidad,
          precio_u: parseFloat(det.precio),
          precio_t: det.precio * det.cantidad
        }
        this.webService.insertPedidosDetalle(detalle).then((data) => { });
      });


      if (tipoPedido != 4 && tipoPedido != 6 && tipoPedido != 7 && tipoPedido != 8) {
        console.log("Enviar correo", tipoPedido);
        this.enviarCorreoPedido(pedido, this.id_pedido, tipoPedido, this.codigoDescuento);
      }

    });

  }

  enviarCorreoPedido(pedido, id_pedido, tipoPedido, codigoDescuento) {
    this.toaster.warning('Espere un momento ..', '', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    setTimeout(() => {
      this.webService.enviarMail(pedido, id_pedido, tipoPedido, this.nombreEmpresa, this.carrito, this.configuracion, codigoDescuento).then((resMail: any) => {
        // console.log("Respuesta mail", resMail);
        this.loading = false;
        // this.toaster.success('Pedido enviado!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
        let mensaje = 'Transacción se ha realizado con Éxito';
        let referencia = 'Nro. Referencia: ' + id_pedido;
        let estadoTransaccion = 1;
        this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
        // this.webService.vaciarCarrito();
        // this.router.navigateByUrl("/");
      });
    }, 5000);
  }

  open(content, tipoPedido, loadingPagoPayphone) {
    // 1 = Recoger en la tienda
    // 2 = Transferencia Bancaria
    // 3 = Pagar cuando le entreguen
    // 4 = Pago con Payphone
    // 5 = Enviar pedido al whatasapp
    // 6 = pago con Data Fast
    // 7 = pago con PlacetoPay

    // Mostrar logo que redireccione a www.payphone.app cuando la compra es por PayPhone
    if (tipoPedido == 4) {
      this.logoBtnPagoFormulario.payPhone = true;
    } else {
      this.logoBtnPagoFormulario.payPhone = false;
    }

    // Mostrar logo que redireccione a www.datafast.com.ec cuando la compra es por DataFast
    if (tipoPedido == 6) {
      this.logoBtnPagoFormulario.dataFast = true;
    } else {
      this.logoBtnPagoFormulario.dataFast = false;
    }

    // Mostrar logo que redireccione a www.placetopay.com cuando la compra es por PlacetoPay
    if (tipoPedido == 7) {
      this.logoBtnPagoFormulario.placetoPay = true;
    } else {
      this.logoBtnPagoFormulario.placetoPay = false;
    }

    // Mostrar logo que redireccione a www.placetopay.com cuando la compra es por PlacetoPay
    if (tipoPedido == 8) {
      this.logoBtnPagoFormulario.paypal = true;
    } else {
      this.logoBtnPagoFormulario.paypal = false;
    }


    // Mostrar los terminos y condiciones
    this.terminosCondiciones.btnActivo = false;

    // Mostrar datos de Cuenta Bancaria
    if (tipoPedido == 2) {
      this.datosCuentaBancaria.mostrarDatosCuenta = true;
    } else {
      this.datosCuentaBancaria.mostrarDatosCuenta = false;
    }

    if (this.carrito.length == 0) {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
    } else {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        let datosFormulario = false;

        // Si es cedula
        if (this.datosPersonales.cedula.length == 10) {
          console.log("Cedula");
          if (
            this.datosPersonales.nombres
            && this.datosPersonales.apellidos
            && this.datosPersonales.correo
            && this.datosPersonales.telefono
            && this.datosPersonales.ciudad
            && this.datosPersonales.direccion
          ) {
            if (this.webService.validadSoloLetras(this.datosPersonales.nombres) == true) {
              if (this.webService.validadSoloLetras(this.datosPersonales.apellidos) == true) {
                datosFormulario = true;
              } else {
                this.toaster.error('Error en el Apellido, No pueden contener carácteres especiales.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
              }
            } else {
              this.toaster.error('Error en el Nombre, No pueden contener carácteres especiales.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
            }
          } else {
            datosFormulario = false;
            this.toaster.error('Campos vacios, Verifique e intente nuevamente.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
        }

        // Si es Ruc
        if (this.datosPersonales.cedula.length == 13) {
          console.log("Ruc");
          if (this.datosPersonales.nombres
            && this.datosPersonales.correo
            && this.datosPersonales.telefono
            && this.datosPersonales.ciudad
            && this.datosPersonales.direccion
          ) {
            datosFormulario = true;
          } else {
            datosFormulario = false;
            this.toaster.error('Campos vacios, Verifique e intente nuevamente.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
        }

        // Verificar correo y numero telefono y enviar a guardar el Pedido
        if (datosFormulario == true) {
          if (this.webService.validarCorreo(this.datosPersonales.correo) == true) {
            if (this.webService.validarSoloNumeros(this.datosPersonales.telefono) == 0) {
              if (this.datosPersonales.registrar == true) {
                console.log("Ir a registrar");
                this.webService.registrarClienteBilling(this.datosPersonales).then((resRegistro: any) => {
                  // console.log("respuesta", resRegistro);
                  if (resRegistro.rta == 1) {
                    this.guardarPedido(tipoPedido, loadingPagoPayphone);
                  } else {
                    this.guardarPedido(tipoPedido, loadingPagoPayphone);
                  }
                });
              } else {
                console.log("Ya esta registrado");
                this.guardarPedido(tipoPedido, loadingPagoPayphone);
              }
            } else {
              this.toaster.error('Número de teléfono no válido.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
            }
          } else {
            this.toaster.error('Correo electrónico no válido.', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
        }

      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });

    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  enviarTransaccionPayphone(id_pedido, pedido, configuracion, dataPayphone, verificarCtaPayphone, respuesta, tipoPedido, loadingPagoPayphone, codigoDescuento) {
    let dataCompra = {
      phoneNumber: verificarCtaPayphone.numero,
      countryCode: verificarCtaPayphone.dialCode,
      amount: respuesta.totalPagar * 100,
      AmountWithoutTax: respuesta.totalPagar * 100,
      ClientTransactionId: id_pedido,
      storeId: dataPayphone.secret_key
    }
    // console.log("Dato de compra", dataCompra);
    // Pago con tarjeta
    this.webService.enviarPagoPayphone(dataCompra, dataPayphone).then((respCompra: any) => {
      // console.log("Respuesta de compra", respCompra.transactionId);
      this.loading = false;
      if (respCompra.transactionId) {
        this.nombreEmpresa

        // Asignarle solo el id de la transaccion para que actualice
        pedido.id_transaccion = respCompra.transactionId;
        //pedido.id_pedido = id_pedido;

        // Guardar en localStorege por si refrescan la pantalla ddel navegador
        let pedidoLocalStorage = {
          id_pedido: this.id_pedido,
          transactionId: respCompra.transactionId,
          tipoPedido: tipoPedido,
          fechActual: this.webService.obtenerFechaActual(0),
          nombreEmpresa: this.nombreEmpresa,
          pedido: pedido,
          carrito: this.carrito,
          codigoDescuento: codigoDescuento
        }

        let s = this.localStorage.setItem(configuracion.loginStorage + '-pedido', pedidoLocalStorage).subscribe((dataPedido) => {
          // console.log("data del pedido LS", dataPedido);
          this.webService.updatePedido(id_pedido, pedido).then((res) => { console.log(res); });
          s.unsubscribe();
        });
        // Monitorizar el estado de la compra
        this.loadingPayphone(loadingPagoPayphone);
        this.comprobarEstadoCompra(respCompra.transactionId, this.webService, configuracion, dataPayphone, id_pedido, this.localStorage, this.codigoDescuento);
      } else {
        // console.log(respCompra);
        if (respCompra.error.message) {
          this.webService.deletePedidoDetalle(this.id_pedido).then((res) => {
            console.log("Detalle Pedido", res);
          });
          this.webService.deletePedido(this.id_pedido).then((res) => {
            console.log("Pedido", res);
          });
          this.toaster.warning(respCompra.error.message, '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
        } else {
          if (respCompra.error.errors.message) {
            console.log("respCompra.error.errors.message");
            this.toaster.warning(respCompra.error.errors.message, '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
        }
      }
    });
  }

  productoWhatsApp(pedido) {
    if (this.carrito.length > 0) {
      this.prodWhatsApp = "";
      this.webService.getInformacion().then((dataInformacion: any) => {
        // console.log("Informcaion", dataInformacion[0].whatsapp);
        // console.log(this.carrito);
        if (dataInformacion[0].whatsapp) {
          for (var i = 0; i < this.carrito.length; i++) {
            this.prodWhatsApp += "* " + this.carrito[i].nombre + ' | Cant: ' + this.carrito[i].cantidad + ' | PrecioU : ' + this.carrito[i].precio + ", ";
          }
          let text = this.prodWhatsApp.substring(0, this.prodWhatsApp.length - 2);
          window.open("https://wa.me/" + dataInformacion[0].whatsapp + "?text=Hola, mi nombre es: " + pedido.nombres + " " + pedido.apellidos + ", me interesan los productos de su tienda: " + text + ". | Total compra: " + pedido.total + " | Costo envío " + pedido.costoEnvio + " | Descuento x cod. " + pedido.descuentoCodigo + " | Total a Pagar: " + pedido.totalPagar + ".", "_blank");
        } else {
          this.toaster.error('Número de WhatsApp no configurado', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
        }
      });
    } else {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, });
    }
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      // console.log(data[0]);
      this.configuracion = data[0];

      // Dominio para hacer pruebas localmente
      //this.dataFast.dominioPagina = data[0].dominioPagina + '/carrito';
      this.dataFast.dominioPagina = 'http://localhost:4200/carrito';
      // this.configuracion.dominioPagina = data[0].dominioPagina + '/carrito';
      this.configuracion.dominioPagina = 'http://localhost:4200/carrito';

      // Obtener la configuracion del Boton enviar pedido 1
      this.btnEnviarPedido1.texto = data[0].txtBtnEnviarPedido1;
      this.btnEnviarPedido1.aplicarCostoEnvio = data[0].aplicarCostoEnvioBtn1;

      // Obtener variable para mostrar/ocultar boton whatasapp
      this.visibilidadBtnWhatsapp = data[0].visibilidadBtnWhatsapp;

      // Obtener POrcentaje cuando compra con tarjeta
      this.compraTarjetaCredito.porcentajeCompraTarjeta = data[0].porcentajeCompraTarjeta;
      this.compraTarjetaCredito.txtRecargoTarjetaC = data[0].txtRecargoTarjetaC;

      // Obtener el porcentaje para el codigo de descuento
      this.codigoDescuento.texto = data[0].txtCodigoDescuento;
      this.codigoDescuento.porcentaje = data[0].porcentajeCodigoDescuento;

      // Color del div para mostrar el costo de envio que sea igual al menu
      let colorCosEnv = document.getElementById('colorCostoEnvio');
      let colorCosEnvResp = document.getElementById('colorCostoEnvioResponsivo');

      // Color del div para mostrar el costo de envio que sea igual al menu
      let colorCosEnv2 = document.getElementById('colorCostoEnvio2');
      let colorCosEnvResp2 = document.getElementById('colorCostoEnvioResponsivo2');

      // Obtener si posee los terminos y condiciones
      this.terminosCondiciones.visualizar = data[0].terminosCondiciones;

      if (data[0].costoEnvio > 0) {
        this.costoEnvio = (parseFloat(data[0].costoEnvio).toFixed(2));
        this.textoCostoEnvio = data[0].textoCostoEnvio + ' ' + this.costoEnvio;
        // Mostrar texto y costo de envio
        colorCosEnv.style.background = data[0].colorPrincipal;
        colorCosEnvResp.style.background = data[0].colorPrincipal;
      } else {
        this.costoEnvio = (data[0].costoEnvio);
        // Ocultar texto y costo de envio
        colorCosEnv.style.display = 'none';
        colorCosEnvResp.style.display = 'none';
      }

      if (data[0].costoEnvio2 > 0) {
        this.costoEnvio2 = (parseFloat(data[0].costoEnvio2).toFixed(2));
        this.textoCostoEnvio2 = data[0].textoCostoEnvio2 + ' ' + this.costoEnvio2;
        // Mostrar texto y costo de envio
        colorCosEnv2.style.background = data[0].colorPrincipal;
        colorCosEnvResp2.style.background = data[0].colorPrincipal;
        this.selectTipoEnvio.verSelect = true;
        this.selectTipoEnvio.opcionSeleccionada = false;
      } else {
        this.costoEnvio2 = (data[0].costoEnvio2);
        this.selectTipoEnvio.opcionSeleccionada = true;
        // Ocultar texto y costo de envio
        colorCosEnv2.style.display = 'none';
        colorCosEnvResp2.style.display = 'none';
      }

      // Color para el menu establecido por el admin
      let m = document.getElementById('main-navbar');
      m.style.background = data[0].colorPrincipal;

      if ((this.totalCarrito) > 0 && (this.totalCarrito <= this.configuracion.valor_minimo_compra)) {
        // console.log("Mostrar boton");
        this.datosCuentaBancaria.valorMininoCompra = true;
      }

      // verificar si la compra aun esta en proceso, para cargarla nuevamente
      let s = this.localStorage.getItem(this.configuracion.loginStorage + '-pedido').subscribe((dataPedido: any) => {
        // console.log("Local Storge", dataPedido);
        if (dataPedido) {
          this.carrito = {};

          for (var i = 0; i < dataPedido.carrito.length; i++) {
            let prod: productoCarrito = {
              nombre: dataPedido.carrito[i].nombre,
              cantidad: dataPedido.carrito[i].cantidad,
              id_prod: dataPedido.carrito[i].id_prod,
              precio: dataPedido.carrito[i].precio,
              img_web: dataPedido.carrito[i].img_web,
              stockReal: dataPedido.carrito[i].stockReal
            };
            this.webService.agregarAlCarrito(prod, 0);
          }

          this.carrito = this.webService.getCarrito();

          for (var i = 0; i < this.carrito.length; i++) {
            this.carrito[i].stockDisponible = this.webService.crearArregloStock(this.carrito[i].stockReal);
          }

          this.totalCarrito = this.webService.getTotal().toFixed(2);

          this.codigoDescuento.aplicado = dataPedido.codigoDescuento.aplicado;
          this.codigoDescuento.codigo = dataPedido.codigoDescuento.codigo;
          this.codigoDescuento.descuento = dataPedido.codigoDescuento.descuento;
          this.codigoDescuento.porcentaje = dataPedido.codigoDescuento.porcentaje;
          this.codigoDescuento.totalReal = dataPedido.codigoDescuento.totalReal;

          // Si el pago es con PayPhone
          if (dataPedido.tipoPedido == 4) {
            this.comprobarEstadoCompra(dataPedido.transactionId, this.webService, this.configuracion, this.pasarelasPago.payPhone, dataPedido.id_pedido, this.localStorage, dataPedido.codigoDescuento);
            // this.modalService.open(this.loadingPagoPayphone, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
            this.modalService.open(this.loadingPagoPayphone, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
              console.log("Ejecutarr 3333", ejecutar);
              // console.log("respuesta al cerrar", result);
              switch (result) {
                case 1: {
                  this.router.navigateByUrl("/");
                  console.log("Transaccion exitosa", result);
                  break;
                }
                case 2: {
                  clearInterval(ejecutar);
                  this.loading = true;
                  let s = this.localStorage.getItem(this.configuracion.loginStorage + '-pedido').subscribe((dataPedido: any) => {
                    let transaccion = {
                      id: dataPedido.transactionId
                    }
                    this.webService.cancelarCompraPayphone(transaccion, this.pasarelasPago.payPhone).then((dataCancelar) => {
                      console.log("Cancelar transaccion", dataCancelar);
                      this.loading = false;
                      if (dataCancelar == true) {
                        clearInterval(ejecutar);
                        this.toaster.success('Transacción cancelada con éxito', '', { timeOut: 4000, positionClass: 'toast-bottom-center' });
                      } else {
                        this.toaster.error('Error al cancelar la transacción', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
                      }
                    })
                    s.unsubscribe();
                  });
                  this.localStorage.removeItem(this.configuracion.loginStorage + '-pedido').subscribe((deletePedidoLS) => { });
                  console.log("Transaccion Cancelada", result);
                  break;
                }
                case 3: {
                  console.log("Transacción expirada o cancelada", result);
                  break;
                }
              }
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }

          // Si el pago es con DataFast
          // if (dataPedido.tipoPedido == 6) {
          //   if (this.dataFast.resourcePath) {
          //     // LLamar a la funcion para procesar la transaccion de DataFast si es que hay algun dato en this.dataFast.resourcePath
          //     this.procesarTransaccionDatafast(this.pasarelasPago.dataFast, this.dataFast.resourcePath, dataPedido, this.configuracion);
          //   } else {
          //     this.webService.deletePedidoDetalle(dataPedido.id_pedido).then((res) => { });
          //     this.webService.deletePedido(dataPedido.id_pedido).then((res) => { });
          //     this.toaster.error('No se ha podido realizar el pago, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          //   }
          // }
        }
        s.unsubscribe();
      });


      // Verificar estado de trasaccion DataFast al regresar al comercio
      if (this.dataFast.id_pedido) {
        console.log("Verificar Estado Transaccion DataFast");
        this.loading = true;
        this.consultarEstadoTransaccionDataFast(this.pasarelasPago.dataFast, this.dataFast.id_pedido, this.configuracion, 1);
      }

      // Verificar estado de trnasaccion PlacetPay al regresar al comercio
      if (this.placetoPay.id_pedido) {
        console.log("Verificar Estado Transaccion Placetopay");
        this.webService.getPedidoId(this.placetoPay.id_pedido).then(async (res: any) => {
          // console.log("Regresooo", res[0]);
          if (res[0]) {
            if (res[0].id_transaccion) {
              this.loading = true;
              await this.consultarEstadoTransaccionPlacetoPay(res[0].id_transaccion, this.placetoPay.id_pedido, this.configuracion, 1);
              this.loading = false;
            }
          } else {
            console.log("No existe el pedido");
          }
        });
      }

    });

  }

  enviarTransaccionDataFast(id_pedido, pedido, respuesta, tipoPedido, carrito, ipAddress, codigoDescuento) {
    let totBaseTarifa12 = 1.00;
    let iva12 = this.iva;
    let tot = totBaseTarifa12 + iva12;
    let baseTarifa0 = (parseFloat(respuesta.totalPagar) - tot).toFixed(2);
    // let baseTarifa0 = (parseFloat(respuesta.totalPedido) - tot).toFixed(2);
    let monto = (tot + parseFloat(baseTarifa0)).toFixed(2);

    let idIva12 = '004';
    let longIva12 = '012';
    let paramIva12 = this.generarIdentLongValorDataFast(idIva12, longIva12, iva12.toFixed(2));
    // console.log(this.generarIdentLongValorDataFast(idIva12, longIva12, iva12.toFixed(2)));

    let idBaseTarifa0 = '052';
    let longBaseTarifa0 = '012';
    let paramBaseTarifa0 = this.generarIdentLongValorDataFast(idBaseTarifa0, longBaseTarifa0, baseTarifa0);
    // console.log(this.generarIdentLongValorDataFast(idBaseTarifa0, longBaseTarifa0, baseTarifa0));

    // console.log(paramIva12 + '--' + paramBaseTarifa0);

    let paramComercioElectronico = '003' + '007' + '0103910';
    // console.log("diferente1", this.generarIdentLongValorDataFast('003' , '007' , '0103910'));

    let paramProovedorServicio = '051' + '008' + '17913101';
    // console.log("diferente2", this.generarIdentLongValorDataFast('051', '008', '17913101'));

    let idBaseTarifa12 = '053';
    let longBaseTarifa12 = '012';
    let paramBaseTarifa12 = this.generarIdentLongValorDataFast(idBaseTarifa12, longBaseTarifa12, totBaseTarifa12.toFixed(2));
    // console.log(this.generarIdentLongValorDataFast(idBaseTarifa12, longBaseTarifa12, totBaseTarifa12.toFixed(2)));

    let paramCampTotal = '0081';
    let final = paramCampTotal + paramComercioElectronico + paramIva12 + paramProovedorServicio + paramBaseTarifa0 + paramBaseTarifa12;

    let data = {
      customer: {
        givenName: pedido.nombres,
        surname: pedido.apellidos,
        ip: ipAddress,
        email: pedido.correo,
        identificationDocType: 'IDCARD',
        identificationDocId: pedido.cedula_cliente,
        phone: pedido.telefono,
      },
      cart: {
        items: []
      },
      shipping: {
        street1: pedido.direccion_cliente,
        country: 'EC'
      },
      billing: {
        street1: pedido.direccion_cliente,
        country: 'EC'
      },
      merchantTransactionId: id_pedido,
      testMode: 'EXTERNAL',
      customParameters: final,
      risk: {
        parameters: 'PuntoPymes'
      }
    }

    carrito.forEach(det => {
      let p = {
        name: det.nombre,
        description: det.nombre,
        price: parseFloat(det.precio),
        quantity: det.cantidad
      }
      data.cart.items.push(p);
    });

    this.pagarDataFast(tipoPedido, respuesta.totalPagar, data, id_pedido, pedido, carrito, codigoDescuento);
  }

  getDatos() {
    this.webService.getInformacion().then((data) => {
      this.nombreEmpresa = data[0].nombre;
      if (data[0].numeroCuenta && data[0].nombreBanco) {
        this.numeroCuenta = data[0].numeroCuenta.toUpperCase();
        this.nombreBanco = data[0].nombreBanco.toUpperCase();
        this.nombreCuenta = data[0].nombreCuenta.toUpperCase();
        this.datosCuentaBancaria.mostrarBotonCuenta = true;
      }
    });
  }

  // Boton de pago Payphone
  comprobarUsuarioPayPhone(payPhone, content, loadingPagoPayphone) {
    if (this.carrito.length > 0) {
      this.verificarCtaPayphone.numero = '',
        this.modalService.open(payPhone, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          // console.log(this.verificarCtaPayphone);
          if (this.verificarCtaPayphone.numero) {
            this.loading = true;
            if (this.verificarCtaPayphone.numValido == true && this.verificarCtaPayphone.iso2 && this.verificarCtaPayphone.numeroCompleto) {
              let dataUsuario = {
                number: this.verificarCtaPayphone.numero,
                prefix: this.verificarCtaPayphone.dialCode
              }
              // console.log("Pasarela pago", this.pasarelasPago);
              this.webService.comprobarCuentaPayphone(dataUsuario, this.pasarelasPago.payPhone).then((dataPayphone: any) => {
                // console.log("respuesta", dataPayphone);
                if (dataPayphone.documentId) {
                  this.loading = false;
                  // Llamar al modal del formulario del cliente
                  this.open(content, 4, loadingPagoPayphone);
                } else {
                  this.loading = false;
                  this.toaster.warning('Su teléfono no se encuentra registrado en PayPhone, Instale la aplicación y registrese para poder procesar su pago.', '', { timeOut: 6000, positionClass: 'toast-center-center' });
                  setTimeout(() => {
                    window.open("https://www.youtube.com/watch?v=8ikeKLsYVDc", "_blank");
                  }, 5000);
                }
              });
            } else {
              this.loading = false;
              this.toaster.error('Datos no válidos, verifique e intente nuevamenente', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
            }
          } else {
            this.toaster.error('Ingrese su número de celular', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
    }
  }

  public comprobarEstadoCompra(idTransaccion, webService, configuracion, dataPayphone, id_pedido, localStorage, codigoDescuento) {
    // statusCode = 1 => Pending
    // statusCode = 2 => Canceled
    // statusCode = 3 => Approved
    // console.log("Monitoriar Compra");
    ejecutar = setInterval(function () {
      webService.comprobarEstadoCompra(idTransaccion, dataPayphone).subscribe((dataEstado: any) => {
        // console.log("Estados", dataEstado);
        switch (dataEstado.statusCode) {
          case 1: {
            // console.log("Estado Pendiente: ", dataEstado);
            break;
          }

          case 2: {
            clearInterval(ejecutar);
            // console.log("Estado Caducada: ", dataEstado);
            webService.deletePedidoDetalle(id_pedido).then((res) => { });
            webService.deletePedido(id_pedido).then((res) => { });
            localStorage.removeItem(configuracion.loginStorage + '-pedido').subscribe((deletePedidoLS) => { });
            document.getElementById('mostrar-espera').style.display = "none";
            document.getElementById('estado-Cancelar-Transaccion').style.display = "none";
            document.getElementById('estado-Canceled').style.display = "block";
            break;
          }

          case 3: {
            clearInterval(ejecutar);
            // console.log("Estado Aprovada: ", dataEstado);
            let s = localStorage.getItem(configuracion.loginStorage + '-pedido').subscribe((dataPedido: any) => {
              dataPedido.pedido.estado_transaccion = 'APPROVED';
              webService.updatePedido(id_pedido, dataPedido.pedido).then((res) => { });
              webService.enviarMail(dataPedido.pedido, id_pedido, dataPedido.tipoPedido, dataPedido.nombreEmpresa, dataPedido.carrito, configuracion, codigoDescuento).then((resMail: any) => {
                console.log("Respuesta mail", resMail);
              });
              webService.vaciarCarrito();
              s.unsubscribe();
            });
            localStorage.removeItem(configuracion.loginStorage + '-pedido').subscribe((deletePedidoLS) => { });
            document.getElementById('mostrar-espera').style.display = "none";
            document.getElementById('estado-Cancelar-Transaccion').style.display = "none";
            document.getElementById('estado-Aproved').style.display = "block";
            break;
          }
        }
      });
    }, 10000);
  }

  loadingPayphone(loadingPagoPayphone) {
    // 1 = Transaccion exitosa
    // 2 = Cancelar transaccion
    // 3 = Transaccion expiradada
    this.modalService.open(loadingPagoPayphone, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("Ejecutarr 3333", ejecutar);
      // console.log("respuesta al cerrar", result);
      switch (result) {
        case 1: {
          this.router.navigateByUrl("/");
          console.log("Transaccion exitosa", result);
          break;
        }
        case 2: {
          clearInterval(ejecutar);
          this.loading = true;
          let s = this.localStorage.getItem(this.configuracion.loginStorage + '-pedido').subscribe((dataPedido: any) => {
            this.webService.deletePedidoDetalle(dataPedido.id_pedido).then((res) => { });
            this.webService.deletePedido(dataPedido.id_pedido).then((res) => { });
            let transaccion = {
              id: dataPedido.transactionId
            }
            this.webService.cancelarCompraPayphone(transaccion, this.pasarelasPago.payPhone).then((dataCancelar) => {
              console.log("Cancelar transaccion", dataCancelar);
              this.loading = false;
              if (dataCancelar == true) {
                clearInterval(ejecutar);
                this.toaster.success('Transacción cancelada con éxito', '', { timeOut: 4000, positionClass: 'toast-bottom-center' });
              } else {
                this.toaster.error('Error al cancelar la transacción', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
              }
            })
            s.unsubscribe();
          });
          this.localStorage.removeItem(this.configuracion.loginStorage + '-pedido').subscribe((deletePedidoLS) => { });
          break;
        }
        case 3: {
          console.log("Transacción expirada o cancelada", result);
          break;
        }
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // Obtener el codigo y numero celular dependiendo del pais
  getNumber($event) {
    this.verificarCtaPayphone.numeroCompleto = $event;
    // console.log('getNumber', $event);
  }

  // Obtener el codigo del pais
  onCountryChange($event) {
    this.verificarCtaPayphone.iso2 = $event.iso2;
    // console.log('onCountryChange', $event);
  }

  hasError($event) {
    this.verificarCtaPayphone.numValido = $event;
    // console.log('hasError', $event);
  }

  telInputObject($event) {
    this.verificarCtaPayphone.iso2 = $event.s.iso2;
    this.verificarCtaPayphone.dialCode = $event.s.dialCode;
    // console.log('telInputObject', $event.s);
  }
  // Fin Obtener el codigo y numero celular dependiendo del pais

  acuerdoCostoEnvio(alertcostoEnvio, tipoPedido) {
    // 1 = Recoger en la tienda
    // 2 = Transferencia Bancaria
    // 3 = Pagar cuando le entreguen
    // 4 = Pago con Payphone
    // 5 = Enviar pedido al whatasapp
    this.totalPedidoCostoEnvio = 0;
    // this.selectTipoEnvio.opcionSeleccionada = false;
    this.selectTipoEnvio.tipoPedido = 0;
    if (this.carrito.length == 0) {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
    } else {
      this.totalPedidoCostoEnvio = (parseFloat(this.totalCarrito) + parseFloat(this.costoEnvio)).toFixed(2);
      this.selectTipoEnvio.tipoPedido = tipoPedido;

      if (this.costoEnvio > 0) {
        this.modalService.open(alertcostoEnvio, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          this.open(this.content, tipoPedido, this.loadingPagoPayphone);
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          console.log("Cancelar", reason);
          // Si hay el coto de envio 2 reiniciar oara que eliga el tipo de envio
          if (this.configuracion.costoEnvio2 > 0) {
            this.selectTipoEnvio.opcionSeleccionada = false;
          }
        });
      } else {
        this.open(this.content, tipoPedido, this.loadingPagoPayphone);
      }
    }
  }

  obtenerCantidadProducto(s, producto) {
    // console.log(s.target.value);
    // console.log(producto);
    producto.cantidad = s.target.value;
    this.recalcularTotal();
  }

  // tipoPedido = 4 => Pago con Payphone
  // tipoPedido = 6 => Pago con DataFast
  // tipoPedido = 7 => Pago con PlacetoPay
  // tipoPedido = 8 => Pago con Paypal
  alertcostoEnvioTarjetC(alertcostoEnvioTC, tipoPedido) {
    this.selectTipoEnvio.tipoPedido = 0;
    if (this.carrito.length == 0) {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
    } else {
      this.totalPedidoCostoEnvio = 0;
      this.porcentajeCompraTC = 0;
      // this.selectTipoEnvio.opcionSeleccionada = false;
      this.selectTipoEnvio.tipoPedido = tipoPedido;
      if (this.compraTarjetaCredito.porcentajeCompraTarjeta > 0) {
        let porc = this.compraTarjetaCredito.porcentajeCompraTarjeta;
        let tot = (parseFloat(this.totalCarrito) + parseFloat(this.costoEnvio)).toFixed(2);
        this.porcentajeCompraTC = ((this.totalCarrito * porc) / 100);
        this.totalPedidoCostoEnvio = (parseFloat(tot) + this.porcentajeCompraTC).toFixed(2);
      } else {
        this.totalPedidoCostoEnvio = (parseFloat(this.totalCarrito) + parseFloat(this.costoEnvio)).toFixed(2);
      }
      this.modalService.open(alertcostoEnvioTC, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (tipoPedido == 4) {
          this.comprobarUsuarioPayPhone(this.payPhone, this.content, this.loadingPagoPayphone);
        }

        if (tipoPedido == 6) {
          this.open(this.content, tipoPedido, '');
        }

        if (tipoPedido == 7) {
          console.log("Pagar con Placetopay");
          this.open(this.content, tipoPedido, '');
        }

        if (tipoPedido == 8) {
          console.log("Pagar con Paypal");
          this.open(this.content, tipoPedido, '');
        }

      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log("Cancelar", reason);
        // Si hay el coto de envio 2 reiniciar oara que eliga el tipo de envio
        if (this.configuracion.costoEnvio2 > 0) {
          this.selectTipoEnvio.opcionSeleccionada = false;
        }
      });
    }
  }

  verificarCodigoDescuento(codigo) {
    if (this.carrito.length == 0) {
      this.toaster.error('No existen productos en su Carrito!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
    } else {
      if (codigo) {
        // console.log("COdigo descuento", this.codigoDescuento);
        this.loading = true;
        this.webService.verificarCodigoDescuento(codigo).then((res: any) => {
          // console.log(res);
          this.loading = false;
          if (res.rows > 0) {
            if (this.codigoDescuento.aplicado == false) {
              // console.log(this.totalCarrito);
              // console.log(this.codigoDescuento);
              this.codigoDescuento.descuento = ((this.totalCarrito * this.codigoDescuento.porcentaje) / 100).toFixed(2);
              this.totalCarrito = (this.codigoDescuento.totalReal - parseFloat(this.codigoDescuento.descuento)).toFixed(2);
              this.codigoDescuento.aplicado = true;
              this.toaster.success('Código aplicado con éxito', '', { timeOut: 2000, positionClass: 'toast-bottom-center' });
            } else {
              this.toaster.warning('Su código ya ha sido aplicado', '', { timeOut: 2000, positionClass: 'toast-bottom-center' });
            }
          } else {
            this.toaster.error('Código no válido', 'Error', { timeOut: 2000, positionClass: 'toast-bottom-center' });
          }
        });
      } else {
        this.toaster.error('Ingrese su código de descuento', 'Error', { timeOut: 2000, positionClass: 'toast-bottom-center' });
      }
    }
  }

  calcularDescuentosRecargosPedido(tipoPedido, costoEnvio, compraTarjetaCredito, codigoDescuento) {
    // console.log(codigoDescuento);
    let porcentajeTC;
    let porcentajeCD;
    let reacargoTarjeta;
    let descuentoCodigo;
    let totalPagar;
    let total = this.webService.getTotal();
    let totDesc;
    let totTC;

    if (tipoPedido == 4 || tipoPedido == 6 || tipoPedido == 7 || tipoPedido == 8) {
      porcentajeTC = compraTarjetaCredito.porcentajeCompraTarjeta;
    } else {
      porcentajeTC = 0;
    }

    if (this.codigoDescuento.aplicado == true) {
      porcentajeCD = codigoDescuento.porcentaje;
    } else {
      porcentajeCD = 0;
    }

    // console.log("total", total);
    descuentoCodigo = ((total * porcentajeCD) / 100).toFixed(2);
    totDesc = total - descuentoCodigo;
    // console.log(porcentajeCD);
    // console.log("Desc x cod", descuentoCodigo);
    // console.log("total1", totDesc);
    // console.log("====");

    reacargoTarjeta = ((totDesc * porcentajeTC) / 100).toFixed(2);
    // console.log(porcentajeTC);
    // console.log("Rec x TC", reacargoTarjeta);

    totTC = totDesc + parseFloat(reacargoTarjeta);
    // console.log("total2", total);
    totalPagar = (parseFloat(totTC) + parseFloat(costoEnvio)).toFixed(2);

    // console.log("total3", totalPagar);

    let costosDetallePedido = {
      totalPedido: total,
      totalPagar: totalPagar,
      costoEnvio: parseFloat(costoEnvio),
      porcentajeCompraTarjeta: porcentajeTC,
      porcentajeCodigoDescuento: porcentajeCD,
      recargoTarjeta: reacargoTarjeta,
      descuentoCodigo: descuentoCodigo
    }

    return costosDetallePedido;
  }

  // Boton de pagos DataFast todo el proceso
  pagarDataFast(tipoPedido, totalPagar, datosTransaccion, id_pedido, pedido, carrito, codigoDescuento) {
    this.loading = true;


    this.webService.getCheckOutIdDataFast(this.pasarelasPago.dataFast, totalPagar, datosTransaccion).then((resp: any) => {
      console.log("respuesta DataFast", resp);
      if (resp.id) {
        this.dataFast.checkoutId = resp.id;
        this.dataFast.dominioPagina = this.dataFast.dominioPagina + '?idPedido=' + id_pedido;

        // Crear el script para generar formulario
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = this.pasarelasPago.dataFast.url + "/v1/paymentWidgets.js?checkoutId=" + this.dataFast.checkoutId;
        this.elementRef.nativeElement.appendChild(s);


        // Abrir el modal para mostrar el formulario como ventana emergente
        this.modalService.open(this.modalDataFast, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          if (reason == 'cancelar') {
            this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
            this.webService.deletePedido(id_pedido).then((res) => { });
          }
          console.log("Cancelar", reason);
        });

      } else {
        this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
        this.webService.deletePedido(id_pedido).then((res) => { });
        this.router.navigateByUrl("/catalogo");
        // this.toaster.error('Error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-center' });
      }
      this.loading = false;
    });
  }

  // Consultar estado de la transaccion en datafast por Id_pedido
  // tipoComprobacion 0 = Comprobar Pedidos del usuario Pendientes
  // tipoComprobacion 1 = Comprobar estado del Pedido al regresar al comercio
  async consultarEstadoTransaccionDataFast(dataFast, id_pedido, configuracion, tipoComprobacion) {
    await this.webService.getPedidoId(id_pedido).then((pedido: any) => {
      let dataPedido = pedido[0];
      // console.log(pedido);
      this.webService.estadoTransaccionDatafastIdPedido(dataFast, id_pedido).then((res: any) => {
        console.log(res);
        let estadoTransaccion;
        let mensaje = '';
        let referencia = '';
        let tipoPedido = 6;
        if (res.result) {
          if (res.result.code = '000.000.100') {
            referencia = 'Nro. Referencia: ' + id_pedido;
            let codigoEstado = res.payments[0].result.code;
            if (codigoEstado == '000.100.112' || codigoEstado == '000.000.000') {
              estadoTransaccion = 1;
              dataPedido.estado_transaccion = 'APPROVED';
              dataPedido.id_transaccion = res.payments[0].id;
              mensaje = 'Transacción realizada con Éxito.';
              this.webService.updatePedido(id_pedido, dataPedido).then((resActualizar) => { });
              this.webService.transaccionAprobadaEnviarCorreo(id_pedido, dataPedido, tipoPedido, configuracion);
            } else {
              estadoTransaccion = 2;
              mensaje = 'Transacción no procesada, Intente nuevamente. Si esto persiste comuniquese con su entidad Financiera.';
              this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
              this.webService.deletePedido(id_pedido).then((res) => { });
            }
          }
        } else {
          if (res.error) {
            estadoTransaccion = 4;
            this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
            this.webService.deletePedido(id_pedido).then((res) => { });
            mensaje = 'Error al generar la transación, Intente nuevamente';
          }
        }
        // SI algo falla es por esta Parte funcion Asincrona
        if (tipoComprobacion == 1) {
          this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
        }
        if (tipoComprobacion == 0) {
          console.log("Se hizo un cambio por debajo");
          // this.webService.vaciarCarrito();
        }
        this.loading = false;
      });
    });
  }
  // Fin Boton de pagos DataFast todo el proceso

  generarIdentLongValorDataFast(idCampo, longitud, monto) {
    let convMonto = monto.toString();
    // console.log("monto enviado", convMonto);

    let valor = convMonto.replace('.', '');
    let long = parseInt(longitud);
    let totCeros = long - valor.length;
    let final;

    if (long == convMonto.length) {
      final = idCampo + longitud + valor;
    } else {
      let ceros = '0';
      for (var i = 0; i < totCeros - 1; i++) {
        ceros += '0';
      }
      final = idCampo + longitud + ceros + valor;
    }

    return final;
  }

  getCiudad(c) {
    this.datosPersonales.ciudad = c.target.value;
  }

  buscarCliente(cedula) {
    if (cedula.length == 10 || cedula.length == 13) {
      // console.log(cedula);
      this.webService.validarDocumentoIdentidad(this.datosPersonales.cedula).then((res: any) => {
        if (res.valor == 0) {
          this.toaster.error('Cédula o Pasaporte incorrecto, verifíque e intente nuevamente', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          this.encerarDatosPersonales(true, cedula);
        } else {
          this.loading = true;
          this.datosPersonales.editar = false;
          this.toaster.warning('Espere un momento...', '', { timeOut: 1000, positionClass: 'toast-bottom-center' });
          this.webService.buscarClienteCedulaRuc(cedula).then((res: any) => {
            this.loading = false;
            if (res.length > 0) {
              // console.log("cliente billing", res[0]);
              this.datosPersonales.nombres = this.convertirTextoMayusculaMin(res[0].nombres);
              this.datosPersonales.apellidos = this.convertirTextoMayusculaMin(res[0].apellidos);
              this.datosPersonales.correo = res[0].email;
              this.datosPersonales.telefono = res[0].celular;
              this.datosPersonales.direccion = this.convertirTextoMayusculaMin(res[0].direccion);
              this.datosPersonales.registrar = false;
            } else {
              this.encerarDatosPersonales(false, cedula);
            }
          });
        }
      });
    } else {
      this.encerarDatosPersonales(true, cedula);
    }
  }

  convertirTextoMayusculaMin(str) {
    // console.log("lleggagaga", str);
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

  }

  getPasarelasPago() {
    this.webService.obtenerBotonesPago().then((res: any) => {
      // console.log(res);
      res.forEach(b => {
        if (b.nombre == "PayPhone") {
          this.pasarelasPago.payPhone.nombre = b.nombre;
          this.pasarelasPago.payPhone.token = b.token;
          this.pasarelasPago.payPhone.secret_key = b.secret_key;
          this.pasarelasPago.payPhone.url = b.url;
        }
        if (b.nombre == "DataFast") {
          this.pasarelasPago.dataFast.nombre = b.nombre;
          this.pasarelasPago.dataFast.token = b.token;
          this.pasarelasPago.dataFast.secret_key = b.secret_key;
          this.pasarelasPago.dataFast.url = b.url;
        }
        if (b.nombre == "PlacetoPay") {
          this.pasarelasPago.placetoPay.nombre = b.nombre;
          this.pasarelasPago.placetoPay.token = b.token;
          this.pasarelasPago.placetoPay.secret_key = b.secret_key;
          this.pasarelasPago.placetoPay.url = b.url;
        }
        if (b.nombre == "PayPal") {
          this.pasarelasPago.payPal.nombre = b.nombre;
          this.pasarelasPago.payPal.token = b.token;
          this.pasarelasPago.payPal.secret_key = b.secret_key;
          this.pasarelasPago.payPal.url = b.url;
          // Paypal
          // this.pagarPayPal(b.token);
        }
      });
      // console.log("Pasarelas pago", this.pasarelasPago);
    });

  }

  // Funciones para el boton de pago con PlacetoPay
  async enviarTransaccionPlacetoPay(id_pedido, pedido, respuesta, tipoPedido, carrito, ipAddress, codigoDescuento, configuracion) {

    let documentType = '';

    if (pedido.cedula_cliente.length == 10) {
      documentType = 'CI'
    }

    if (pedido.cedula_cliente.length == 13) {
      documentType = 'RUC'
    }

    let key = await this.webService.createDataAuthPlacetoPay(this.pasarelasPago.placetoPay.secret_key);

    let subTotal = parseFloat((respuesta.totalPedido / (this.iva + 1)).toFixed(2));
    let iva = parseFloat((respuesta.totalPedido - subTotal).toFixed(2));


    pedido.id_pedido = id_pedido;

    let data = {
      buyer: {
        name: pedido.nombres,
        surname: pedido.apellidos,
        email: pedido.correo,
        document: pedido.cedula_cliente,
        documentType: documentType,
        mobile: pedido.telefono
      },
      payment: {
        reference: id_pedido,
        description: pedido.observaciones,
        amount: {
          taxes: [
            {
              kind: "valueAddedTax",
              amount: iva,
              base: subTotal
            },
          ],
          currency: "USD",
          total: respuesta.totalPagar
        }
      },
      expiration: key.fechaAlter,
      ipAddress: ipAddress,
      returnUrl: this.configuracion.dominioPagina + '?id_pedido=' + id_pedido,
      userAgent: navigator.userAgent,
      paymentMethod: "",
      auth: {
        login: this.pasarelasPago.placetoPay.token,
        tranKey: key.tranKey,
        nonce: key.nonce,
        seed: key.fechaNormal
      }
    }

    console.log("Se fueeee", data);

    await this.webService.enviarPagoPlacetoPay(this.pasarelasPago.placetoPay, data).then(async (res: any) => {
      console.log("Respuesta", res);
      if (res.status.status) {
        if (res.status.status = 'OK') {
          // this.toaster.warning('Espere un momento ...', '', { timeOut: 5000, positionClass: 'toast-bottom-center' });
          pedido.id_transaccion = res.requestId;
          await this.webService.updatePedido(id_pedido, pedido).then((res) => { });
          setTimeout(() => {
            window.location.href = res.processUrl;
          }, 5000);
        }
      } else {
        await this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
        await this.webService.deletePedido(id_pedido).then((res) => { });
        this.toaster.error('Transacción no procesada, Inténtelo Nuevamente', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-center' });
      }
    });

  }

  // tipoComprobacion 0 = Comprobar Pedidos del usuario Pendientes
  // tipoComprobacion 1 = Comprobar estado del Pedido al regresar al comercio
  async consultarEstadoTransaccionPlacetoPay(idTransaccion, id_pedido, configuracion, tipoComprobacion) {

    let key = await this.webService.createDataAuthPlacetoPay(this.pasarelasPago.placetoPay.secret_key);

    let data = {
      auth: {
        login: this.pasarelasPago.placetoPay.token,
        tranKey: key.tranKey,
        nonce: key.nonce,
        seed: key.fechaNormal
      }
    }

    await this.webService.estadoTransaccionPlacetoPay(data, this.pasarelasPago.placetoPay, idTransaccion).then(async (res: any) => {

      console.log("Estado transaccion", res);

      await this.webService.getPedidoId(id_pedido).then(async (resPedido: any) => {

        if (res.status.status == 'REJECTED') {
          console.log("Se a cancelado, ir a borrar pedido");
          if (resPedido.length > 0) {
            await this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
            await this.webService.deletePedido(id_pedido).then((res) => { });
          }
          if (tipoComprobacion == 1) {
            // let mensaje = res.status.message;
            let mensaje = "La transacción ha sido rechazada";
            let referencia = 'Nro. Referencia: ' + id_pedido;
            let estadoTransaccion = 2;
            this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
          }
        }

        if (res.status.status == 'APPROVED') {
          console.log("Transaccion exitosa, actualizar estado BD y enviar correos");
          if (resPedido[0].estado_transaccion != 'APPROVED') {
            resPedido[0].estado_transaccion = res.status.status;
            let tipoPedido = 7;
            await this.webService.updatePedido(id_pedido, resPedido[0]).then((resActualizar) => { });
            await this.webService.transaccionAprobadaEnviarCorreo(id_pedido, resPedido[0], tipoPedido, configuracion);
          }
          if (tipoComprobacion == 1) {
            let mensaje = res.status.message;
            let referencia = 'Nro. Referencia: ' + id_pedido;
            let estadoTransaccion = 1;
            this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
          }
        }

        if (res.status.status == 'PENDING') {
          console.log("Transaccion en proceso");
          if (tipoComprobacion == 1) {
            let mensaje = res.status.message;
            let referencia = 'Nro. Referencia: ' + id_pedido;
            let estadoTransaccion = 3;
            this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
          }
          if (tipoComprobacion == 0) {

            this.toaster.info('Usted tiene una Transacción Pendiente, Nro Referencia: ' + id_pedido, '', { timeOut: 7000, positionClass: 'toast-bottom-center' });
          }
        }

      });
    });

  }

  irPoliticas(identificador) {
    this.router.navigateByUrl("politicas/" + identificador);
  }

  getTerminosCondiciones(e) {
    this.terminosCondiciones.btnActivo = e.target.checked;
    // console.log("terminosCondiciones", this.terminosCondiciones);
  }

  encerarDatosPersonales(editar, cedula) {
    this.datosPersonales = {
      nombres: "",
      apellidos: "",
      cedula: cedula,
      direccion: "",
      correo: "",
      telefono: "",
      ciudad: "",
      registrar: true,
      editar: editar
    }
  }

  async obtenerTransacionespendientesBtnPago(pasarelaPago, nombre_pasarela, configuracion, cedula, id_pedido) {

    await this.webService.getPedidosBtnPagosEmpresa(nombre_pasarela).then(async (res: any) => {

      for (let p of res) {

        if (p.estado_transaccion == 'PENDING') {

          if (p.id_pedido != id_pedido) {

            // Si todo va bien hacerlo igual que placetopay
            if (p.pasarela_pago == 'dataFast') {
              console.log("Vamos DataFast");
              await this.consultarEstadoTransaccionDataFast(pasarelaPago, p.id_pedido, configuracion, 0);
            }

            if (p.pasarela_pago == 'placetoPay') {
              if (p.id_transaccion) {
                if (p.cedula_cliente == cedula) {
                  await this.consultarEstadoTransaccionPlacetoPay(p.id_transaccion, p.id_pedido, configuracion, 0);
                }
              } else {
                this.webService.deletePedidoDetalle(p.id_pedido).then((res) => { });
                this.webService.deletePedido(p.id_pedido).then((resDeleteP) => { });
              }
            }

          }

        }

      }

    });
  }

  // estadoTransaccion = 1 => Aprobado
  // estadoTransaccion = 2 => Rechazado
  // estadoTransaccion = 3 => Pendiente
  // estadoTransaccion = 4 => Error al generar el pedido, no se encuentra registrado en Data Fast
  modalResultadoTransaccion(resultadoTransaccion, mensaje, referencia, estadoTransaccion) {
    this.estadoTransaccion.mensaje = mensaje;
    this.estadoTransaccion.estado = estadoTransaccion;
    this.estadoTransaccion.referencia = referencia;
    this.modalService.open(resultadoTransaccion, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigateByUrl("/");
      this.webService.vaciarCarrito();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async modalPedidoPendientePlacetopay(modalPendientePlacetopay, pendientes, id_pedido, pedido, respuesta, tipoPedido, carrito, ipAddress, codigoDescuento, configuracion) {
    this.loading = false;
    this.pendientesPlacetopay = pendientes;
    this.modalService.open(modalPendientePlacetopay, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("Enviar el pedido a place to pay");
      this.loading = true;
      this.enviarTransaccionPlacetoPay(id_pedido, pedido, respuesta, tipoPedido, carrito, ipAddress, codigoDescuento, configuracion);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log("Eliminar pedido de BD");
      this.webService.deletePedidoDetalle(id_pedido).then((res) => { });
      this.webService.deletePedido(id_pedido).then((res) => { });
    });
  }

  private pagarPayPal(pasarelasPago, id_pedido, pedido, respuesta, tipoPedido, carrito, codigoDescuento, configuracion): void {
    let mensaje = '';
    let referencia = '';
    let estadoTransaccion;
    this.payPalConfig = {
      currency: 'USD',
      clientId: pasarelasPago.token,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          description: "Identificador de la transacción" + id_pedido,
          amount: {
            currency_code: 'USD',
            value: respuesta.totalPagar,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: respuesta.totalPagar
              }
            }
          },
          items: this.webService.getDataPaypal(carrito, respuesta)
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: async (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        this.loading = true;
        await actions.order.get().then(async details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
          if (details.status == 'APPROVED') {
            pedido.estado_transaccion = 'APPROVED';
            pedido.id_transaccion = details.id;
            await this.webService.updatePedido(id_pedido, pedido).then((resActualizar) => { });
            await this.webService.getPedidoId(id_pedido).then(async (resPedido: any) => {
              await this.webService.transaccionAprobadaEnviarCorreo(id_pedido, resPedido[0], tipoPedido, configuracion);
            });
          }
        });
        this.loading = false
      },
      onClientAuthorization: async (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.modalService.dismissAll(this.paypalModal);
        referencia = 'Nro. Referencia: ' + id_pedido;
        mensaje = 'Transacción realizada con Éxito.';
        estadoTransaccion = 1;
        this.router.navigateByUrl("/");
        this.webService.vaciarCarrito();
        this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        this.modalService.dismissAll(this.paypalModal);
        estadoTransaccion = 2;
        mensaje = 'Transacción ha sido cancelada.';
        this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
      },
      onError: err => {
        console.log('OnError', err);
        this.modalService.dismissAll(this.paypalModal);
        estadoTransaccion = 4;
        mensaje = 'Error al generar la transación, Intente nuevamente';
        this.modalResultadoTransaccion(this.resultadoTransaccion, mensaje, referencia, estadoTransaccion);
        // this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // console.log("llamar al otrroooo, hacer otra cosaaa");
        // this.resetStatus();
      },
    };
  }

  // valorLocal = 1 Dentro de la ciudad
  // valorLocal = 0 Fuera de la ciudad
  public async obtenerTipoEnvioLocalFuera(e) {
    this.selectTipoEnvio.opcionSeleccionada = true;
    this.selectTipoEnvio.value = e.target.value;
    let respuesta;
    if (this.selectTipoEnvio.value == 1) {
      respuesta = this.calcularDescuentosRecargosPedido(this.selectTipoEnvio.tipoPedido, this.costoEnvio, this.compraTarjetaCredito, this.codigoDescuento);
      this.costoEnvio = respuesta.costoEnvio;
    }
    if (this.selectTipoEnvio.value == 0) {
      respuesta = this.calcularDescuentosRecargosPedido(this.selectTipoEnvio.tipoPedido, this.costoEnvio2, this.compraTarjetaCredito, this.codigoDescuento);
      this.costoEnvio2 = respuesta.costoEnvio;
    }

    this.totalCarrito = respuesta.totalPedido;
    this.totalPedidoCostoEnvio = respuesta.totalPagar;
  }


}



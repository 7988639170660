import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/web.service';
import { Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { async } from 'rxjs/internal/scheduler/async';
import { environment } from 'src/environments/environment';

export interface productoCarrito {
  id_prod: number,
  nombre: string,
  precio: number,
  cantidad: number,
  img_web: string,
  stockReal: number
}

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  public informacion: any;

  public imgsBanner = [];

  public loading = false;

  public configuracion: any;

  public promociones: {
    id: "",
    nombrePromocion: "",
    nombreImagen: "",
    imgPromocion: "",
  }

  public urlVideo;
  public mostrarVideo = false;

  public pasarelasPago = {
    placetoPay: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    dataFast: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    payPal: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
  }

  urlBase=environment.firebaseUrl;

  constructor(
    private webService: WebService,
    public meta: Meta,
    private _sanitizer: DomSanitizer
  ) {
    this.webService.getImgBanner().then((data: []) => {
      this.imgsBanner = data;
      // console.log(this.imgsBanner);
    });
  }

  ngOnInit() {
    this.getConfiguracion();
    this.getDatos();
    this.getPromociones();
    this.getPasarelasPago();
  }

  getDatos() {
    this.webService.getInformacion().then((data) => {
      this.informacion = data[0];
      if (this.informacion.video) {
        this.mostrarVideo = true;
        this.pocesarUrlVideo(this.informacion.video);
      }
    })
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion = data[0];
      // console.log('Configuracion inicio', this.configuracion);

      let m = document.getElementById('main-navbar');
      // console.log(data[0].colorPrincipal);
      m.style.background = data[0].colorPrincipal;

      let fotSect = document.getElementById('footer-section');
      fotSect.style.background = data[0].colorPrincipal;

      let p = document.getElementById('sectPromocion');
      p.style.background = data[0].colorPrincipal;

      this.meta.updateTag({ property: "og:url", content: this.configuracion.dominioPagina });
      this.meta.updateTag({ property: "og:image", content: "https://drive.google.com/open?id=15Rqx2hoxpiQoR7bN5eioTO2WIjJaFv8r" });
    });
  }

  getPromociones() {
    this.webService.getPromociones().then((data: any) => {
      this.promociones = data;
    })
  }

  pocesarUrlVideo(url) {
    let s = '=';
    let a = url.split(s, 2);
    this.urlVideo = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + a[1]);
  }

  getPasarelasPago() {
    this.webService.obtenerBotonesPago().then(async (res: any) => {
      // console.log(res);
      if (res.length > 0) {
        for (let b of res) {
          if (b.nombre == "DataFast") {
            this.pasarelasPago.dataFast.nombre = b.nombre;
            this.pasarelasPago.dataFast.token = b.token;
            this.pasarelasPago.dataFast.secret_key = b.secret_key;
            this.pasarelasPago.dataFast.url = b.url;
            await this.obtenerTransacionespendientesBtnPago(this.pasarelasPago.dataFast);
          }
          if (b.nombre == "PlacetoPay") {
            this.pasarelasPago.placetoPay.nombre = b.nombre;
            this.pasarelasPago.placetoPay.token = b.token;
            this.pasarelasPago.placetoPay.secret_key = b.secret_key;
            this.pasarelasPago.placetoPay.url = b.url;
            await this.obtenerTransacionespendientesBtnPago(this.pasarelasPago.placetoPay);
          }
          if (b.nombre == "PayPal") {
            this.pasarelasPago.payPal.nombre = b.nombre;
            this.pasarelasPago.payPal.token = b.token;
            this.pasarelasPago.payPal.secret_key = b.secret_key;
            this.pasarelasPago.payPal.url = b.url;
            console.log("Ir a validar");
            await this.obtenerTransacionespendientesBtnPago(this.pasarelasPago.payPal);
          }
        }

      }

    });
  }

  // Obtner transacciones pendientes de las pasarela de pagos
  async obtenerTransacionespendientesBtnPago(pasarelasPago) {
    await this.webService.getPedidosBtnPagosEmpresa(pasarelasPago.nombre).then(async (res: any) => {
      if (res.length) {
        for (let p of res) {
          if (p.estado_transaccion == 'PENDING') {

            if (p.pasarela_pago == 'dataFast') {
              console.log("Vamos DataFast");
              await this.webService.estadoTransaccionDatafastIdPedido(pasarelasPago, p.id_pedido).then(async (res) => {
                await this.actualizarTransaccionesPendientesDataFast(res, p, this.configuracion);
              });
            }

            if (p.pasarela_pago == 'placetoPay') {
              if (p.id_transaccion) {
                console.log("Vamos PlacetoPay");
                let key = await this.webService.createDataAuthPlacetoPay(pasarelasPago.secret_key);
                let data = {
                  auth: {
                    login: this.pasarelasPago.placetoPay.token,
                    tranKey: key.tranKey,
                    nonce: key.nonce,
                    seed: key.fechaNormal
                  }
                }
                // console.log(JSON.stringify(data));
                // console.log(p.id_transaccion);
                await this.webService.estadoTransaccionPlacetoPay(data, pasarelasPago, p.id_transaccion).then(async (res) => {
                  await this.actualizarTransaccionesPendientesPlacetoPay(res, p, this.configuracion);
                });

              } else {
                console.log("No hay id_trasnacccion");
                await this.webService.deletePedidoDetalle(p.id_pedido).then((res) => { });
                await this.webService.deletePedido(p.id_pedido).then((res) => { });
              }
            }

            if (p.pasarela_pago == 'payPal') {
              console.log("Estamos en pay pal");
              await this.eliminarTransaccionPendientesPayPal(p)
            }

          }
        }
      } 
    });
  }

  async actualizarTransaccionesPendientesDataFast(transaccion, pedido, configuracion) {
    if (transaccion.result) {
      if (transaccion.result.code = '000.000.100') {
        let codigoEstado = transaccion.payments[0].result.code;
        let tipoPedido = 6;
        if (codigoEstado == '000.100.112' || codigoEstado == '000.000.000') {
          pedido.estado_transaccion = 'APPROVED';
          pedido.id_transaccion = transaccion.payments[0].id;
          await this.webService.updatePedido(pedido.id_pedido, pedido).then((resActualizar) => { });
          await this.webService.transaccionAprobadaEnviarCorreo(pedido.id_pedido, pedido, tipoPedido, configuracion);
          // await this.webService.vaciarCarrito();
        } else {
          await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
          await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
        }
      }
    } else {
      if (transaccion.error) {
        console.log("No existe la transacion en la pasarela");
        await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
        await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
      }
    }
  }

  async actualizarTransaccionesPendientesPlacetoPay(transaccion, pedido, configuracion) {

    console.log(transaccion.status.status);

    if (transaccion.status.status == 'REJECTED') {
      console.log("Eliminar pedido", pedido.id_pedido);
      await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
      await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
    }

    if (transaccion.status.status == 'APPROVED') {
      console.log("Cambiar estado y enviar correo", pedido.id_pedido);
      let tipoPedido = 7;
      pedido.estado_transaccion = 'APPROVED';
      await this.webService.updatePedido(pedido.id_pedido, pedido).then((resActualizar) => { });
      await this.webService.transaccionAprobadaEnviarCorreo(pedido.id_pedido, pedido, tipoPedido, configuracion);
      // await this.webService.vaciarCarrito();
    }

  }

  // Eliminar si no tiene id_transaccion por que nunca se concreto
  async eliminarTransaccionPendientesPayPal(pedido) {
    await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
    await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
  }


}

import { Component, OnInit } from '@angular/core';
import { productoCarrito, WebService } from '../../web.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

var groupBy = require('json-groupby')

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {

  public categorias = [];

  public loading = true;

  public productos = [];

  public productosAgrupados = [];

  public imgProductos = [];

  public url = "https://www.facebook.com/sharer/sharer.php?u="

  public busqueda = "";

  public productosBusqueda = [];
  public productosAgrupadosBusqueda = [];

  public mostrarInformacion = {
    mostrarProductoCategoria: true,
    mostrarProductosBusqueda: false
  }

  public productoCompraDirecta = {
    cantidad: 0,
    id_prod: "",
    img_web: "",
    nombre: "",
    precio: 0.00
  };

  numProdCarrito = 0;
  public carrito = [];

  public configuracion: any;

  public tipoPrecio;

  public porcentajePrecio;

  // Porcentaje para aplicarle descuento al producto
  public porcentajeDescuento = 0;

  constructor(
    private webService: WebService,
    private localStorage: LocalStorage,
    public router: Router,
    public meta: Meta,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.getConfiguracion();
    this.webService.getCategorias().then((data: []) => {
      this.categorias = data;
      (this.categorias[0]) ? this.listarProductos(this.categorias[0].id) : this.loading = false;
      // console.log("Categorias all", this.categorias)
    });
  }

  async listarProductos(grupo_id) {
    // console.log("Id Grupo", grupo_id);
    this.tipoPrecio = "";
    this.mostrarInformacion.mostrarProductoCategoria = true;
    this.mostrarInformacion.mostrarProductosBusqueda = false;
    // this.loading = true;
    await this.webService.getProductosPorGrupo(grupo_id).then(async (data: []) => {
      if (this.porcentajeDescuento > 0) {
        this.productos = await this.webService.aplicarDescuentoPromocional(grupo_id, data, this.porcentajeDescuento);
      } else {
        this.productos = data;
      }
      // console.log("productos", data);
      this.productos.forEach(p => {
        p.url = "https://www.facebook.com/sharer/sharer.php?u=" + this.configuracion.dominioPagina + "/producto/" + p.pro_cod;
        p.stockReal = parseFloat(parseFloat(p.stockactual).toFixed(2));
        // obtener imagen del producto
        let arrImg = [
          { 'id': 1, 'imagen': p.imagen_uno },
          { 'id': 2, 'imagen': p.imagen_dos },
          { 'id': 3, 'imagen': p.imagen_tres },
          { 'id': 4, 'imagen': p.imagen_cuatro },
        ]
        p.img_web = this.webService.obtenerImagenPrincipalProducto(arrImg);
        p.imagenes = arrImg;
      });
      // console.log(this.productos);
      let l = this.localStorage.getItem(this.configuracion.loginStorage).subscribe((login) => {
        if (login) {
          this.tipoPrecio = login.default_price;
          console.log("If login:", this.tipoPrecio);
          this.definirTipoPrecio(this.productos, this.tipoPrecio);
        } else {
          this.webService.getConfiguracion().then((dataConfiguracion: any) => {
            this.tipoPrecio = dataConfiguracion[0].tipoPrecio;
            console.log("Else No login:", this.tipoPrecio);
            this.definirTipoPrecio(this.productos, this.tipoPrecio);
          });
        }
      });

      // ir hacia arriba
      if (screen.width >= 768 && screen.height >= 481) {
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
            window.scrollTo(0, pos - 20);
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 5);
      }

    });
  }

  definirTipoPrecio(data, tipoP) {
    for (var i = 0; i < data.length; i++) {
      for (var p in data[i]) {
        if (p == tipoP) {
          if (data[i][p]) {
            data[i].precioMostrarOferta = data[i][p];
            if (this.porcentajePrecio) {
              let calPorcentaje = ((data[i][p] * this.porcentajePrecio) / 100);
              let precio = parseFloat(data[i][p]);
              data[i].precioMostrarNormal = (precio + calPorcentaje).toFixed(2);
            }
          } else {
            data[i].precioMostrar = data[i].pA;
            if (this.porcentajePrecio) {
              let calPorcentaje = ((data[i].pA * this.porcentajePrecio) / 100);
              let precio = parseFloat(data[i].pA);
              data[i].precioMostrarNormal = (precio + calPorcentaje).toFixed(2);
            }
          }
        }
      }
    }
    this.productosAgrupados = this.eliminarDuplicados(data, 'pro_cod');
    // console.log("Productos", this.productosAgrupados);
  }

  eliminarDuplicados(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    this.loading = false;
    return newArray;
  }

  // No esta activa
  agregarAlCarrito(p) {
    let prod: productoCarrito = {
      nombre: p.pro_nom,
      cantidad: 1,
      id_prod: p.pro_cod,
      precio: 15.25,
      img_web: p.img_web,
      stockReal: p.stockactual
    };
    this.webService.agregarAlCarrito(prod, 1);
  }

  addCarrito(p) {
    let prod: productoCarrito = {
      nombre: p.pro_nom,
      cantidad: 1,
      id_prod: p.pro_cod,
      precio: p.precioMostrarOferta,
      img_web: p.img_web,
      stockReal: p.stockReal
    };
    console.log("acaca", prod );
    
    this.webService.agregarAlCarrito(prod, 1);
  }

  irAGrupo(p) {
    this.router.navigateByUrl("grupo/" + p.pro_cod);
  }

  async buscarProducto() {
    this.tipoPrecio = "";
    this.productosBusqueda = [];
    if (this.busqueda) {
      this.loading = true;
      await this.webService.getProductosBusqueda(this.busqueda).then(async (data: any) => {
        if (this.porcentajeDescuento > 0) {
          data = await this.webService.aplicarDescuentoPromocional(data.productogrupo_codigo, data, this.porcentajeDescuento);
        }
        this.loading = false;
        if (Object.keys(data).length > 0) {
          this.productosBusqueda = data;
          this.productosBusqueda.forEach(p => {
            p.url = "https://www.facebook.com/sharer/sharer.php?u=" + this.configuracion.dominioPagina + "/producto/" + p.pro_cod;
            p.stockReal = parseFloat(parseFloat(p.stockactual).toFixed(2));
            let arrImg = [
              { 'id': 1, 'imagen': p.imagen_uno },
              { 'id': 2, 'imagen': p.imagen_dos },
              { 'id': 3, 'imagen': p.imagen_tres },
              { 'id': 4, 'imagen': p.imagen_cuatro },
            ]
            p.img_web = this.webService.obtenerImagenPrincipalProducto(arrImg);
            p.imagenes = arrImg;
          });
          // console.log("Prod busqueda", data);

          let l = this.localStorage.getItem(this.configuracion.loginStorage).subscribe((login) => {
            if (login) {
              this.tipoPrecio = login.default_price;
              console.log("SIiii Login", this.tipoPrecio);
              this.definirTipoPrecioBusqueda(data, this.tipoPrecio);
            } else {
              this.webService.getConfiguracion().then((dataConfiguracion: any) => {
                this.tipoPrecio = dataConfiguracion[0].tipoPrecio;
                console.log("Nooo Login", this.tipoPrecio);
                this.definirTipoPrecioBusqueda(data, this.tipoPrecio);
              });
            }
          });
          this.mostrarInformacion.mostrarProductoCategoria = false;
          this.mostrarInformacion.mostrarProductosBusqueda = true;
        } else {
          this.toaster.warning('No se encontro coincidencias!', '', { timeOut: 3000 });
        }
      });
    } else {
      this.toaster.error('Ingrese el producto a buscar!', 'Error', { timeOut: 3000 });
    }
  }

  definirTipoPrecioBusqueda(data, tipoP) {
    for (var i = 0; i < data.length; i++) {
      for (var p in data[i]) {
        if (p == tipoP) {
          if (data[i][p]) {
            data[i].precioMostrarOferta = data[i][p];
            if (this.porcentajePrecio) {
              let calPorcentaje = ((data[i][p] * this.porcentajePrecio) / 100);
              let precio = parseFloat(data[i][p]);
              data[i].precioMostrarNormal = (precio + calPorcentaje).toFixed(2);
            }
          } else {
            data[i].precioMostrar = data[i].pA;
            if (this.porcentajePrecio) {
              let calPorcentaje = ((data[i].pA * this.porcentajePrecio) / 100);
              let precio = parseFloat(data[i].pA);
              data[i].precioMostrarNormal = (precio + calPorcentaje).toFixed(2);
            }
          }
        }
      }
    }
    this.productosAgrupadosBusqueda = this.eliminarDuplicados(data, 'pro_cod');
    // console.log("busqueda", this.productosAgrupadosBusqueda);
  }

  getConfiguracion() {
    this.loading = true;
    this.webService.getConfiguracion().then((data: any) => {
      this.loading = false;
      this.configuracion = data[0];
      this.porcentajePrecio = this.configuracion.porcentajePrecioOferta;
      this.porcentajeDescuento = data[0].porcentajeDescuento;
      // console.log("configuracion catalogo", this.configuracion);
      let m = document.getElementById('main-navbar');
      m.style.background = data[0].colorPrincipal;
      this.meta.updateTag({ property: "og:url", content: data[0].dominioPagina });
      this.meta.updateTag({ property: "og:image", content: "https://drive.google.com/open?id=15Rqx2hoxpiQoR7bN5eioTO2WIjJaFv8r" });
    });
  }

  compartirFacebook(p) {
    let urlpro = this.configuracion.dominioPagina + "/grupo/" + p.pro_cod;
    let urlCompartir = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlpro);
    window.open(urlCompartir, 'sharer', '_blank');
  }

}
